import React from "react"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
import { Layout } from "../components/Layout"
import { AdminLogin } from "../components/AdminLogin"

const AsyncMemberList = loadable(() => import("../components/MemberList"))

const AdminPage = () => {
  const [authenticated, setAuthenticated] = React.useState(false)

  const handleSuccess = () => setAuthenticated(true)

  return (
    <Layout>
      <Helmet>
        <title>Admin | Lifespring Votes</title>
      </Helmet>

      {authenticated ? (
        <AsyncMemberList />
      ) : (
        <AdminLogin onSuccess={handleSuccess} />
      )}
    </Layout>
  )
}

export default AdminPage
