import React from "react"

type Props = {
  onSuccess: () => void
}

export function AdminLogin(props: Props) {
  const [password, setPassword] = React.useState("")
  const [hasError, setHasError] = React.useState(false)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (password === "585lifespring585") {
      setHasError(false)
      setPassword("")
      props.onSuccess()
    } else {
      setHasError(true)
      setPassword("")
    }
  }

  return (
    <div className="flex justify-center bg-white border border-gray-200 rounded-md py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <h2 className="mb-8 text-3xl leading-9 font-extrabold text-gray-900">
          Lifespring Votes Admin
        </h2>
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Password
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              id="password"
              className="form-input block w-full sm:text-sm sm:leading-5"
              type="password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
            {hasError && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
          {hasError && (
            <p className="mt-2 text-sm text-red-600" id="password-error">
              Password is incorrect.
            </p>
          )}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
